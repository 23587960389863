import React, { useState, useEffect} from 'react';
import { Row, Col, Card, Nav,NavItem,NavLink,TabContent,TabPane, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import classnames from 'classnames';
import {useParams} from 'react-router-dom';
import BankDetails from './BankDetails';
import ResetPassword from './ResetPassword';
import KycDetails from './KycDetails';
import BillingDetails from './BillingDetails';
import EmailNotification from './EmailNotification';
import SmsNotification from './SmsNotification';
import CustomerRating from './CustomerRating';
import MergeAccounts from './MergeAccounts';
import ApiIntegration from '../ApiIntegration/ApiIntegration';
import ModifyChannelIntegeration from '../ModifyChannelIntegeration';
import SmsService from '../SmsService';


const MerchantDetails = (props) => {

  const [activeTab, setActiveTab] = useState('2');
  const [loader, setLoader] = useState(false);
  
  const {merchantId,tab} = useParams();


  useEffect(() => {
      setActiveTab(tab);
          // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
      const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
      }

  return (
    <div className="app-content content overflow-hidden">
      <Row>
       <Col sm={12}>
          <div className="p-0 my-3 merchantDetails content-wrapper">
            <div className="content-header pl-3 mb-4 d-flex justify-content-between flex-wrap">
                <div className="page-title">Update Merchant Details</div>
                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                  <Link to='/merchants' className="btn-text btn-light ml-auto"><span className="icon"><ArrowLeft /></span><span>Back to Merchant List</span></Link>
                </div>
            </div>
            <Row>
              <Col md={3}>
                <Nav vertical tabs pills className="mb-2 border-0">
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}> Bank Details </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }}> Reset Password  </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '5' })} onClick={() => { toggle('5'); }}> KYC Details  </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '6' })} onClick={() => { toggle('6'); }}> Billing Details  </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '7' })} onClick={() => { toggle('7'); }}> Email Notification  </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '8' })} onClick={() => { toggle('8'); }}> SMS Notification  </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '9' })} onClick={() => { toggle('9'); }}> Customer Rating  </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '10' })} onClick={() => { toggle('10'); }}> Merge Account  </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '11' })} onClick={() => { toggle('11'); }}> API Credentials  </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === '12' })} onClick={() => { toggle('12'); }}> Channel Integeration</NavLink>
                    <NavLink className={classnames({ active: activeTab === '13' })} onClick={() => { toggle('13'); }}> SMS Service</NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col md={9}>                    
                <Card className="shadow border-0">
                    <CardBody className="p-4">
                        <TabContent activeTab={activeTab} className="pb-0">
                          <TabPane tabId="2">
                            <BankDetails user={props.user} merchantId={merchantId} />
                          </TabPane>

                          <TabPane tabId="4">
                            <ResetPassword merchantId={merchantId} />               
                          </TabPane>

                          <TabPane tabId="5">
                            <KycDetails merchantId={merchantId} />               
                          </TabPane>

                          <TabPane tabId="6">
                            <BillingDetails merchantId={merchantId} />
                          </TabPane>

                          <TabPane tabId="7">
                            <EmailNotification merchantId={merchantId} />
                          </TabPane>
                          <TabPane tabId="8">
                            <SmsNotification merchantId={merchantId} />
                          </TabPane>
                          <TabPane tabId="9">
                            <CustomerRating merchantId={merchantId} />
                          </TabPane>
                          <TabPane tabId="10">
                            <MergeAccounts merchantId={merchantId} user={props.user} />
                          </TabPane>
                          <TabPane tabId="11">
                            <ApiIntegration merchantId={merchantId} user={props.user} setLoader={setLoader} />
                          </TabPane>
                          <TabPane tabId="12">
                            <ModifyChannelIntegeration merchantId={merchantId} user={props.user} setLoader={setLoader} />
                          </TabPane>
                          <TabPane tabId="13">
                            <SmsService merchantId={merchantId} user={props.user} setLoader={setLoader} />
                          </TabPane>
                        </TabContent>
                    </CardBody>
                </Card> 
              </Col>
            </Row>
          </div>
      </Col>
      
      </Row>
       </div>
  );
}

export default MerchantDetails;
         