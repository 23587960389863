import React, {useEffect, useState} from 'react'
import axios from 'axios';
import { baseString } from '../config.js';
import Swal from "sweetalert2";
import { MultiSelect } from "react-multi-select-component";
import { checkData} from "../ObjectExist.js";
import {
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  Col,
  Row,
  Button,
  Alert,
  CardTitle,
} from "reactstrap";
import { FaEye } from "react-icons/fa";
import placedOrderimg from "../assets/img/PlacedOrderTemplate.jpeg"
import shortaddressimg from "../assets/img/ShortAddressTemplate.jpeg"


function SmsService(props) {


    const [setting_value,set_Setting_value] = useState(0);
    const [shortAddress, setshortAddress] = useState(0);
    const [error, setError] = useState("");
    const [brandName , setBrandName] = useState([]);
    const [allBrand,setAllBrand] = useState([]);
    const [orderPlaced,setOrderPlaced] = useState(0);
    const [ShowImgPlaceorder,setShowImgPlaceorder ] = useState(false);
    const [showImgShortOrder,setshowImgShortOrder ] = useState(false);
    
    

    const allBrandMultiSelectSettings = {
      "allItemsAreSelected": "All items are selected.",
      "clearSearch": "Clear Search",
      "noOptions": "No options",
      "search": "Search",
      "selectAll": "Select All",
      "selectSomeItems": "Select Brand"
  }

  const allBrandFetch = [];
  
  if (
    checkData(allBrand)
  ) {
    allBrand.map(function (value) {
      let tempObj = {
        label: value.brand_name,
        value: value.id
      };
      return allBrandFetch.push(tempObj);
    });
  }

  const brand_id = [];

  if (
    checkData(brandName)
  ) {
    brandName.map(function (data) {
      let id = data.value
      return brand_id.push(id);
    });
  }


     const fetchBrand = ()=>{
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_MERCHANT_BRANDS}`,
        data: {"merchant_id":props.merchantId},
        headers: { token: window.btoa(baseString) },
      }).then((response)=>{
        setAllBrand(response.data.data);
      })
     }

     useEffect(()=>{
      fetchBrand()
     },[])


    const handleSMSChange = ()=>{
        set_Setting_value((val)=>(val === 0 ? 1:0));
        
      }


      const handlePlaceOrder = () => {
        setOrderPlaced((val)=>(val === 0 ? 1:0))
      };

      const handleShortAddress = ()=>{
        setshortAddress((val)=>(val === 0 ? 1:0))
      }


      const handleSubmit = (event)=>{
        event.preventDefault()


        if(brandName.length === 0){
          setError("Please Select the Brand Name");
          return;
        }
        setError("");

        axios({
          method: 'post',
          url: `${process.env.REACT_APP_MERCHANT_SETTINGss}`,
          data: {
            merchant_id: props.merchantId,
            setting_value:setting_value,
            brand_id :brand_id,
            setting_id: "6", 
            orderPlaced_status:orderPlaced,
            shortAddress_status:shortAddress,
          },
          headers: { token: window.btoa(baseString) },
        }).then((response)=>{
          if (response.data.error === false) {
            Swal.fire({
              title: "Success",
              text: response.data.msg
                ? response.data.msg
                : "Data Saved Successfully",
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Error",
              text: response.data.msg
                ? response.data.msg
                : "Something went wrong",
              icon: "error",
            });
          }
        })
        .catch(function (err) {
          Swal.fire({
            title: "Error",
            text: err,
            icon: "error",
          });
        });
      }

      

  return (
    <div>
      <Form>
    <div className="sub-page-header" >
        <CardTitle tag="h4" className="sub-page-title">
          SMS Service
        </CardTitle>
      </div>
      <Col sm={6}>
          <FormGroup style={{ marginBottom: 0 }}>
            <CustomInput
              type="switch"
              id="SMSswitch"
              checked={setting_value}
              onChange={handleSMSChange}
              label={setting_value ? "Active" : "Inactive"}
              value={setting_value ? 1 : 0}
            />
          </FormGroup>
        </Col>
        <Col md={3} lg={3} style={{marginTop:"20px"}} >
          <FormGroup className="mb-md-0">
            <Label>Select Brand :</Label>
            <MultiSelect
              options={allBrandFetch}
              value={brandName}
              labelledBy="Select Brand"
              overrideStrings={allBrandMultiSelectSettings}
              onChange={(e)=>setBrandName(e)}
                />
            </FormGroup>
          </Col>
        <FormGroup tag="fieldset" style={{marginTop:"40px"}}>
           <Label style={{marginBottom:"30px"}}>
            <b>Templates :</b>
           </Label>
           <Row className="pl-3 pl-md-4 align-items-center">
            <div className="col-4 col-md-2 d-flex align-items-center pr-0 pt-0">
              <CustomInput
            type="checkbox"
            id="orderPlaced"
            name="orderPlaced"
            label="Order Placed"
            value={orderPlaced ? 1 : 0}
            checked={orderPlaced}
            onChange={handlePlaceOrder}
          />
          </div>
          
          <FormGroup className="col-8 col-md-6 pl-0 mb-0 position-relative">
              
              {ShowImgPlaceorder && <img src={placedOrderimg} alt="Hover Image" className="img-fluid" style={{position:"absolute",top:"-310px",width:"100%", height:"300px", display:"block"}} />}
              <FaEye
                   onMouseEnter={() => setShowImgPlaceorder(true)}
                   onMouseLeave={() => setShowImgPlaceorder(false)}
                   className="d-none d-md-block"
                   style={{color:"#1b5a8f", cursor:"pointer",position:"relative",top:"-4px",left:"7px"}}
                    />
          </FormGroup>
              
        </Row>

        <Row className="pl-3 pl-md-4 align-items-center">
          <div className="col-6 col-md-2 d-flex align-items-center pr-0 pt-0" >
          <CustomInput
            type="checkbox"
            id="shortAddress"
            name="shortAddress"
            label="Short Address"
            value={shortAddress ? 1 : 0}
            checked={shortAddress}
            onChange={handleShortAddress}
          />
          </div>
          
          
            <FormGroup className="col-6 col-md-6 pl-0 mb-0 d-flex justify-content-start align-items-center position-relative">
              
              
              {showImgShortOrder && <img src={shortaddressimg} alt="Hover Image" style={{position:"absolute",top:"-310px",width:"100%", height:"300px", display:"block"}} />}
              <FaEye
                   onMouseEnter={() => setshowImgShortOrder(true)}
                   onMouseLeave={() => setshowImgShortOrder(false)}
                   className="d-none d-md-block"
                   style={{color:"#1b5a8f", cursor:"pointer",position:"relative",top:"-4px",left:"7px"}}
                    />
                
            </FormGroup>
             
        </Row>
        
        </FormGroup>

        <Form>
        <Button
          className="btn-sm ctm-btn btn btn-primary mt-2"
          onClick={handleSubmit}
          type="submit"
        >
          Submit
        </Button>
      </Form>

      {error && (
        <Alert style={{ marginTop: "20px" }} color="danger">
          {error}
        </Alert>
      )}  
    </Form>

    </div>
  )
}

export default SmsService
